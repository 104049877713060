<template>
  <div>
    <b-row v-if="formaPagamento">
      <b-col cols="12">
        <b-card :title="$t('checkout.credito.titlePrimeiroCard')">
          <b-card-text>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-row>
                  <b-col cols="12">
                    <b-alert
                      show
                      class="mt-2"
                      variant="warning"
                    >
                      <div class="alert-body">
                        {{ $t('checkout.primeiroTextAlertBody') }}
                      </div>
                    </b-alert>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <b-form-radio-group
                      v-for="item in formaPagamento"
                      :key="item.id"
                      v-model="checkoutData.forma"
                      inline
                    >
                      <span>
                        <b-form-radio
                          v-show="formaPagamento.length>1"
                          :value="item"
                          :name="item.formaPagamento"
                          class="custom-control-success"
                        />
                        <b-avatar
                          :src="getIcon(item.descricao)"
                        />
                        <strong class="ml-1">{{ item.formaPagamento }}</strong>
                      </span>

                    </b-form-radio-group>
                  </b-col>
                  <b-col cols="8">
                    <h6 class="text-base font-weight-semibold mb-1">
                      {{ $t('checkout.subTitleAdress') }}
                    </h6>
                    <p class="text-sm">
                      {{ checkoutData.forma.address }}
                    </p>
                  </b-col>
                  <b-col cols="4">
                    <h6 class="text-base font-weight-semibold mb-1">
                      {{ $t('checkout.subTitleNetWork') }}
                    </h6>
                    <p class="text-sm">
                      {{ checkoutData.forma.network }}
                    </p>
                  </b-col>
                  <b-col cols="12">
                    <h6 class="text-base font-weight-semibold mb-1">
                      {{ $t('checkout.subTitleAmount') }}
                    </h6>
                    <p class="text-sm">
                      {{ $t('checkout.credito.quantidade') }}
                    </p>
                  </b-col>
                  <b-col cols="12">
                    <b-alert
                      show
                      class="mt-2"
                      variant="warning"
                    >
                      <div class="alert-body">
                        {{ $t('checkout.segundoTextAlertBody') }}
                      </div>
                    </b-alert>
                  </b-col>
                  <b-col cols="12">
                    <validation-observer ref="refVForm">
                      <b-form
                        autocomplete="off"
                        @submit.prevent="validationForm"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Transaction ID"
                          vid="transactionId"
                          rules="required"
                        >
                          <b-overlay
                            :show="isOverlayVisible"
                            rounded="lg"
                            opacity="0.6"
                            @hidden="onHidden"
                          >
                            <template v-slot:overlay>
                              <div class="d-flex align-items-center">
                                <b-spinner
                                  small
                                  type="grow"
                                  variant="secondary"
                                />
                                <b-spinner
                                  type="grow"
                                  variant="dark"
                                />
                                <b-spinner
                                  small
                                  type="grow"
                                  variant="secondary"
                                />
                                <!-- We add an SR only text for screen readers -->
                                <span class="sr-only"> {{ $t('checkout.primeiroSpan') }}</span>
                              </div>
                            </template>
                            <b-input-group>
                              <b-form-input
                                id="transactionId"
                                v-model="checkoutData.forma.transactionId"
                                :placeholder="$t('checkout.placeholderTxId')"
                                :state="errors.transactionId ? false : null"
                              />
                              <b-input-group-append>
                                <b-button
                                  ref="button"
                                  :variant="errors[0] ? 'danger' : 'success'"
                                  type="submit"
                                >{{ $t('checkout.enviarBtn') }}
                                  <feather-icon icon="SendIcon" />
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-overlay>
                        </validation-provider>
                      </b-form>
                    </validation-observer>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>

      <b-modal
        v-model="isDialog"
        title="Checkout"
        hide-footer
        hide-header-close
      >
        <!-- Dialog Content -->
        <template v-if="orderResponse.status === 'Pago'">
          <b-card>
            <b-card-text>

              <b-alert
                variant="success"
                class="d-flex align-items-center justify-content-center mb-3"
                :show="isDialog"
              >
                <div class="alert-body">
                  <span>
                    {{ $t('checkout.textAlertSuccess') }}
                  </span>
                </div>
              </b-alert>

              <p
                class="mb-3 mt-3 text-center"
              >
                {{ $t('checkout.credito.textAlertSucesso') }}
              </p>
            </b-card-text>

            <b-card-text class="d-flex justify-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                href="/"
              >
                {{ $t('checkout.homeBtn') }}
              </b-button>
            </b-card-text>
          </b-card>
        </template>
        <template v-else-if="orderResponse.status === 'Erro'">
          <b-card>
            <b-card-text>
              <b-alert
                variant="danger"
                class="d-flex align-items-center justify-content-center mb-3"
                :show="isDialog"
              >
                <div class="alert-body">
                  <span>
                    {{ $t('checkout.textAlertStatusErro') }}
                  </span>
                </div>
              </b-alert>

              <p class="mb-3 mt-3 text-center">
                {{ orderResponse.obs }}
              </p>
            </b-card-text>

            <b-card-text class="d-flex justify-end">
              <b-button
                variant="primary"
                @click="isDialog = false"
              >
                {{ $t('checkout.fecharBtn') }}
              </b-button>
            </b-card-text>
          </b-card>
        </template>
        <template v-else>
          <b-card>
            <b-card-text>
              <b-alert
                variant="warning"
                class="d-flex align-items-center justify-content-center mb-3"
                :show="isDialog"
              >
                <div class="alert-body">
                  <span>
                    {{ $t('checkout.alertPagamento') }}
                  </span>
                </div>
              </b-alert>
              <p class="mb-7 mt-7">
                {{ $t('checkout.primeiroTextAlertPagamento') }}
              </p>
              <p class="mb-3 text-center">
                {{ $t('checkout.segundoTextAlertPagamento') }}
              </p>
              <p class="mb-3 text-center">

                <b-progress
                  :value="progressPaymentValue"
                  max="60"
                  variant="info"
                  class="'progress-bar-info"
                  animated
                  height="1.3rem"
                >
                  <b-progress-bar :value="progressPaymentValue">
                    <strong>{{ progressPaymentValue }} s</strong>
                  </b-progress-bar>
                </b-progress>
              </p>
              <p class="mb-3 text-center">
                {{ $t('checkout.textIntentos') }}<span class="font-weight-bolder">{{ progressPaymentTry }}</span>
              </p>
            </b-card-text>

            <b-card-text class="d-flex justify-end">
              <b-button
                variant="primary"
                @click="fixTransactionId"
              >
                {{ $t('checkout.idTransacaoBtn') }}
              </b-button>
            </b-card-text>
          </b-card>
        </template>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BCardText, BAlert, BForm, BInputGroup,
  BFormInput, BInputGroupAppend, BButton, BOverlay, BProgress, BProgressBar, BFormRadio, BFormRadioGroup, BAvatar, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import checkoutStoreModule from './checkoutStoreModule'

const CHECKOUT_STORE_MODULE_NAME = 'checkout'

/* eslint-disable global-require */
export default {

  components: {
    BCardText,
    BRow,
    BCol,
    BCard,
    BAlert,
    BForm,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BOverlay,
    BProgress,
    BProgressBar,
    BFormRadio,
    BFormRadioGroup,
    BAvatar,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      formaPagamento: [],
      checkoutData: {
        forma: { id: 1, transactionId: '' },
      },
      errors: {
        transactionId: undefined,
      },
      orderResponse: {
        status: '',
        obs: '',
      },
      isDialog: false,
      isOverlayVisible: false,
      progressPaymentValue: 0,
      intervalProgressValue: null,
      progressPaymentTry: 0,
      required,
    }
  },

  created() {
    // store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
    if (!store.hasModule(CHECKOUT_STORE_MODULE_NAME)) store.registerModule(CHECKOUT_STORE_MODULE_NAME, checkoutStoreModule)
    this.getFormaPagamento()
  },

  beforeDestroy() {
    // store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
    if (store.hasModule(CHECKOUT_STORE_MODULE_NAME)) store.unregisterModule(CHECKOUT_STORE_MODULE_NAME)
  },

  methods: {

    onHidden() {
      // Return focus to the button
      this.$refs.button.focus()
    },

    getIcon(simbolo) {
      return `${process.env.VUE_APP_STATIC}icons/${simbolo.toLowerCase()}.png`
    },

    fixTransactionId() {
      this.clearProgressPayment()
      this.isDialog = false
    },

    clearProgressPayment() {
      this.progressPaymentValue = 0
      this.progressPaymentTry = 0
      clearInterval(this.intervalProgressValue)
    },

    progressPayment() {
      clearInterval(this.intervalProgressValue)
      this.intervalProgressValue = setInterval(() => {
        if (this.progressPaymentValue === 60) {
          this.progressPaymentValue = 0
          this.progressPaymentTry += 1
          this.criarPedido()
        }
        this.progressPaymentValue += 1
      }, 1000)
    },

    validationForm() {
      this.$refs.refVForm.validate().then(success => {
        if (success) {
          this.criarPedido()
        }
      })
    },

    async criarPedido() {
      try {
        const payload = {
          idForma: this.checkoutData.forma.id,
          transactionId: this.checkoutData.forma.transactionId,
        }

        this.isOverlayVisible = true

        const response = await store.dispatch('checkout/credito', payload)

        this.orderResponse = response.data

        this.isOverlayVisible = false

        this.isDialog = true

        this.progressPayment()
      } catch (e) {
        this.isOverlayVisible = false

        e.data.forEach(erro => {
          this.errors[erro.campo] = erro.erro

          if (erro.campo === 'transactionId') {
            this.orderResponse.status = 'Erro'
            this.orderResponse.obs = erro.erro
            this.isOverlayVisible = false
            this.isDialog = true
          }
        })
      }
    },

    getFormaPagamento() {
      store
        .dispatch('checkout/getFormaPagamento')
        .then(response => {
          this.formaPagamento = response.data

          if (this.formaPagamento.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.checkoutData.forma = this.formaPagamento[0]
          }
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
